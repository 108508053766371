


























































import { Component } from 'vue-property-decorator';
import AppComponent from '@/mixins/ComponentMixin.vue';

@Component
export default class StateSelector extends AppComponent {
  public dialog = false;
  public valid = false;
  public state: object = {};
  public licenseNumber: string = '';


  public mounted() {
    this.state = this.listOfStates[0];
  }

  public init() {
    this.licenseNumber = '';
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      this.$emit('input', {
        state: this.state,
        licenseNumber: this.licenseNumber,
      });
      this.dialog = false;
    }
  }
}
